.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  padding-top: var(--m5);
  padding-bottom: var(--m5);
  padding-left: var(--m4);
  margin: 0;
  cursor: pointer;
  text-align: left;
  border-bottom: solid 1px var(--border);
}

.wrapper:hover {
  background-color: #ebebeb;
}
.wrapper--dark:hover {
  background-color: #121212;
}
