body {
  /* Colors */
  --primary: #bd0f2d;
  --primary-hovered: #a00d26;
  --primary-placeholder: #f7e0e3;
  --secondary: #bd0f2d;
  --title: #353535;
  --border: rgba(130, 130, 130, 0.35);
  --text: #565656;
  --calendar-b-color: #bd0f2d;
  --calendar-color: white;
  --segment-opacity: rgba(255, 255, 255, 0.8);
  --font-color: black;
  --segment-bg-color: #272727;
  --calendar-today: #eaf6ff;
  --calendar-lines: #ddd;
  --footer-height: 30px;
  --footer-color: #cacaca;

  /* Font sizes */
  --default-font-size: 11px;
  --font-size-ratio: 1.2727; /* square root of golden ratio */
  --f1: calc(
    var(--default-font-size) * var(--font-size-ratio) * var(--font-size-ratio) *
      var(--font-size-ratio)
  );
  --f2: calc(var(--default-font-size) * var(--font-size-ratio) * var(--font-size-ratio));
  --f3: calc(var(--default-font-size) * var(--font-size-ratio));
  --f4: var(--default-font-size);
  --f5: calc(var(--default-font-size) / var(--font-size-ratio));

  /* Margins */
  --m1: 25px;
  --m2: 20px;
  --m3: 15px;
  --m4: 10px;
  --m5: 5px;
  --m6: 3px;
}
body.dark {
  --title: #f3f3e3;
  --segment-opacity: rgba(16, 16, 16, 0.8);
  --font-color: #f3f3e3;
  --gradient-color: rgba(50, 50, 50, 1);
  --calendar-today: #535353;
  --calendar-lines: #808080;
}
body.custom {
  --primary: #bd0f2d ;
  --primary-hovered: #0C1035 ;
  --primary-placeholder: #f7e0e3;
  --secondary: #bd0f2d ;
  --title: #353535;
  --border: rgba(130, 130, 130, 0.35);
  --text: #565656;
  --calendar-b-color: #bd0f2d ;
  --calendar-color: white;
  --segment-opacity: rgba(255, 255, 255, 0.85);
}
body.custom.dark {
  --title: #e6e6e6;
  --segment-opacity: rgba(16, 16, 16, 0.8);
  --font-color: white;
  --gradient-color: rgba(50, 50, 50, 1);
}
body.green {
  --primary: #125e52;
  --primary-hovered: #156f62;
  --primary-placeholder: #d4f9f3;
}
body.green.dark {
  --title: #e6e6e6;
  --segment-opacity: rgba(16, 16, 16, 0.8);
  --font-color: white;
  --gradient-color: rgba(50, 50, 50, 1);
}

.blackoutWrapper:before {
  z-index: 1;
  content: '';
  position: fixed;
  left: var(--x);
  top: var(--y);
  width: 200vw;
  height: 200vw;
  background: radial-gradient(circle closest-side, #ffffff00 1%, black 9%, black);
  transform: translate(-50%, -50%);
  transition: width 0.1s ease, height 0.1s ease;
  pointer-events: none;
}

.rondadeWrapper {
  animation: spin 12s cubic-bezier(0.31, 0, 0.52, 0.99) infinite;
}

.mirrorWrapper {
  transform: scaleX(-1);
}

.invertWrapper {
  filter: invert(1);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  min-height: calc(100vh - var(--footer-height) - 1px);
}
