.title {
  width: 100%;
  padding-left: var(--m3);
  font-size: var(--f3);
  padding-top: var(--m5);
  padding-bottom: var(--m5);
  border-bottom: 1px solid var(--border);
  color: var(--title);
  text-align: left;
}
