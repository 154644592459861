@keyframes loading {
  from {
    transform: translateX(-1em);
    width: 0;
    opacity: 0.15;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  75% {
    opacity: 1;
  }
  to {
    transform: translateX(-1em);
    width: calc(100% + 2em);
    opacity: 0.15;
  }
}
