.rapid-search {
  box-shadow: -1px 1px 8px var(--segment-opacity);
  position: absolute;
  top: 51px;
  left: 0;
  width: 100%;
  background-color: var(--backgroundColor);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  z-index: 10;
}
