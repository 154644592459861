.input {
  background-color: inherit;
  flex-grow: 1;
  margin: 0 0.5em;
  color: white;
  width: 100%;
  outline: none;
  border: 0;
}

.input::placeholder {
  color: var(--primary-placeholder);
}
