.fab {
  position: fixed;
  bottom: calc(var(--bottom) + var(--position) * (var(--size) + var(--spacing)));
  right: var(--right);
  min-height: var(--size);
  min-width: var(--size);
  border-radius: 50%;
  border: none;
  overflow: hidden;
  color: var(--text-color);
  background-color: var(--color);
  box-shadow: 0 1px 3px var(--color);
  transition: ease-in calc(var(--duration) * 1ms);
  text-align: center;
  z-index: calc(25 - var(--position)) !important;
}

.fab:focus {
  outline: none;
}

.fab:not(.fab--loading):hover {
  background-color: var(--color);
  filter: brightness(0.8);
}

.fab--loading {
  background-color: lightgray;
}

.fab--opened {
  border-radius: 0.5em;
}
