.list-wrapper {
  max-height: 20rem;
  overflow-y: auto;
  min-width: 15rem;
}

.list-wrapper--chrome::-webkit-scrollbar-track {
  opacity: 0;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.list-wrapper--chrome::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: white;
}

.list-wrapper--chrome::-webkit-scrollbar-thumb {
  opacity: 0;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.option-item {
  color: black;
  font-weight: inherit;
  white-space: nowrap;
}
.option-item--checked {
  font-weight: bold;
  color: var(--color);
}
.option-item:hover {
  cursor: pointer;
}
